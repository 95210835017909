// extracted by mini-css-extract-plugin
export var category = "search-module--category--84cf6";
export var date = "search-module--date--dd058";
export var excerpt = "search-module--excerpt--8124d";
export var grid = "search-module--grid--7da8c";
export var img = "search-module--img--98f75";
export var inGrid = "search-module--inGrid--fff3c search-module--root--7d395";
export var inList = "search-module--inList--b7220 search-module--root--7d395";
export var leadMediaThumb = "search-module--leadMediaThumb--49a02";
export var root = "search-module--root--7d395";
export var text = "search-module--text--d3c4c";
export var title = "search-module--title--6afae";